<div class="container" [ngClass]="{'app-light-theme': !themeService.isDark, 'app-dark-theme': themeService.isDark}">
	<h1 id="maincontent" tabindex="0">{{'ContactUs.Title' | translate}}</h1>
	<p tabindex="0" class="paragraph" [innerHTML]="'ContactUs.Paragraph' | translate"></p>

	<form [formGroup]="contactForm">
		<mat-form-field appearance="outline">
			<mat-select required id="Content" placeholder="{{'ContactUs.OptionPlaceholder' | translate}}" formControlName="categoryCode" [attr.aria-label]="'ContactUs.OptionPlaceholder'">
				<mat-option *ngFor="let item of [].constructor(numberOfOptions); let i = index" [value]="'ContactUs.Option' + i | translate">{{'ContactUs.Option' + i | translate}}</mat-option>
			</mat-select>
			<mat-error *ngIf="contactForm.get('categoryCode').hasError('required')">{{'ContactUs.Required' | translate}}</mat-error>
		</mat-form-field>
		<mat-form-field appearance="outline">
			<mat-label class="contact-label">{{'ContactUs.Placeholder' | translate}}</mat-label>
			<textarea matInput formControlName="message" required style="height:170px"></textarea>
			<mat-error *ngIf="contactForm.get('message').hasError('required')">{{'ContactUs.Required' | translate}}</mat-error>
		</mat-form-field>
		<button mat-flat-button color="primary" [disabled]="!contactForm.valid" (click)="submit();openDialog()" type="submit">{{'ContactUs.Submit' | translate}}</button>
	</form>
</div>