<div [ngClass]="{'app-light-theme': !themeService.isDark, 'app-dark-theme': themeService.isDark}">
    <div class="title">
        <h1 id="maincontent" tabindex="0">{{'Glossary.Title' | translate}}</h1>
        <div tabindex="0">{{'Glossary.Paragraph' | translate}}</div>
    </div>

    <div class="search">
        <form fxFlex="100" fxLayout="row" fxLayoutAlign="center center" class="search-form">
            <mat-form-field fxFlex="100" class="keywords" appearance="none">
                <input type="text" [formControl]="searchField" matInput (keyup.enter)="getGlossary(searchField.value)" placeholder="{{'Search.Placeholder' | translate}}">
            </mat-form-field>
            <button mat-flat-button color="primary" (click)="getGlossary(searchField.value)" [attr.aria-label]="'Home.Sections.Search' | translate">
				<mat-icon>search</mat-icon>
			</button>
        </form>
    </div>

    <div fxLayout="row" fxLayoutAlign="end center">
        <div class="sort-by" fxLayout="row" fxLayoutAlign="end center">
            <label id="sortby-label">{{ 'Common.Controls.SortBy.Title' | translate }}</label>

            <mat-form-field appearance="outline">
                <mat-select aria-labelledby="sortby-label" ngModel [formControl]="categoryField" id="Content">
                    <mat-option value="" selected (click)="sort(categoryField.value)">{{'Glossary.Desc' | translate}}</mat-option>
                    <mat-option value="0" (click)="sort(categoryField.value)">{{'Glossary.Asc' | translate}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <a (click)="onClick()" class="button" aria-hidden="true">
        <mat-icon class="arrow">arrow_upward</mat-icon>
    </a>

    <!-- Display contents for currently selected letter -->
    <div class="glossary" *ngFor="let currentLetter of (letter !== null ? letter.split('') : alphabet)">
        <div *ngIf="glossaryMap.get(currentLetter) !== undefined">
            <h2 tabindex="0">{{ currentLetter }}</h2>
            <div *ngIf="sortAsc">
                <div *ngFor="let key of getKeys(glossaryMap.get(currentLetter)) | keyvalue: valueAscOrder let first = first; ">
                    <mat-divider *ngIf="!first"></mat-divider>
                    <h3 tabindex="0" appEllipsify [innerHtml]="highlight(key.value)"></h3>
                    <p tabindex="0">{{ glossaryMap.get(currentLetter).get(key.value) }}</p>
                </div>
            </div>
            <div *ngIf="!sortAsc">
                <div *ngFor="let key of getKeys(glossaryMap.get(currentLetter)) | keyvalue: keyDescOrder let first = first; ">
                    <mat-divider *ngIf="!first"></mat-divider>
                    <h3 tabindex="0" appEllipsify [innerHtml]="highlight(key.value)"></h3>
                    <p tabindex="0">{{ glossaryMap.get(currentLetter).get(key.value) }}</p>
                </div>
            </div>
        </div>
    </div>
</div>