<div *ngIf="!isBrowserSupported" class="fixed-header" [ngStyle]="{ 'background-color': 'red' }" role="complementary" attr.aria-label="{{'Common.Notifications.UnsupportedBrowser' | translate}}">
	<div class="unsupported-header" [innerHtml]="'Common.Notifications.BrowserNotSupported' | translate"></div>
</div>
<ng-container *ngIf="environment.headerColor" aria-hidden="true">
	<div *ngIf="environment.headerColor" class="fixed-header" [ngStyle]="{ 'background-color': environment.headerColor }" aria-hidden="true" role="complementary" aria-label="Env">
		<h2 aria-hidden="true">{{ environment.name }}</h2>
	</div>
	<div class="header-bumper" aria-hidden="true"></div>
</ng-container>

<header>
	<div>
		<a *ngIf="loggedIn" tabindex="1" class="skip-option" (keyup)="SkipToSection($event)" (click)="SkipToSection($event)">{{'SkipLink.MainContent' | translate}}</a>
	</div>
	<mat-toolbar fxFlex fxLayoutAlign="space-between center" role="heading" aria-level="1">
		<div fxFlex="42" fxLayoutAlign="start">
			<button *ngIf="loggedIn" [attr.aria-label]="this.menuAriaLabelKey | translate" mat-icon-button (click)="OpenNavigation()">
				<mat-icon class="menu-icon">menu</mat-icon><span class="menu-text">{{menuTextKey | translate}}</span>
			</button>
		</div>
		<a class="logo" href="https://www.ontario.ca/" target="_blank" rel="noopener noreferrer" matTooltip="{{'Common.Notifications.OpensInNewWindow' | translate}}"><img fxLayoutAlign="end" alt="{{'Common.ImageDescriptions.ProvincialLogo' | translate}}" src="assets/img/ontario_logo_header.svg" /></a>
		<div *ngIf="loggedIn" fxFlex="auto" fxLayoutAlign="end" fxHide.gt-md>
			<button fxLayoutGap="1.25rem" type="button" [attr.aria-label]="'Home.Sections.Search' | translate" mat-icon-button (click)="OpenSearch()">
				<mat-icon>search</mat-icon>
			</button>
			<button type="button" [attr.aria-label]="'Home.Titles.Profile' | translate" mat-icon-button matTooltip="{{ GetFormattedUserName(profileName) }}" (click)="OpenProfile()">
				<img aria-hidden="true" alt="" src="assets/img/user_icon.svg">
			</button>
		</div>
		<div fxFlex="41" fxLayout="row" fxLayoutAlign="end center" [fxHide.lt-lg]="loggedIn" >
			<div *ngIf="loggedIn" fxLayoutAlign="end center" fxLayoutGap="1.25rem">
				{{'Header.Welcome' | translate}}&nbsp;<a role="button" tabindex="0" [appA11yActivate]="{method:'OpenProfile', context:this}">{{ GetFormattedUserName(profileName) }}</a>
				<mat-divider vertical></mat-divider>
				<button mat-button (click)="openSignOutPopup()">{{'SignOut.Title' | translate}}</button>
				<mat-divider vertical></mat-divider>
				<div></div>
			</div>
			<button mat-button (click)="toggleLang()">{{(translateService.currentLang === 'en' ? 'Common.Language.French' : 'Common.Language.English') | translate}}</button>
		</div>
	</mat-toolbar>
</header>