import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DatasetModel, ProjectDatasetModel, ProjectDatasetViewModel } from 'src/app/models/dataset.model';
import { ProjectDatasetService } from 'src/app/services/graph/project-dataset.service';
import { ConfirmationPopupService } from 'src/app/shared/services/confirmation-popup.service';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { UploadDropdownModel } from '../dataset-manager/dataset-manager.component';

@Component({
  selector: 'app-dataset-uploader',
  templateUrl: './dataset-uploader.component.html',
  styleUrls: ['./dataset-uploader.component.scss']
})
export class DatasetUploaderComponent implements OnInit {

	@Input() set projectCode(value: string) {
		this._projectCode = value;
		this.initDatasetsDropdown();
	};
	@Input() isMetadataUpdate: boolean = false;

	@ViewChild('fileUpload', {static: false}) fileSelector: ElementRef;
	@ViewChild('form') form : FormGroupDirective;

	_projectCode: string;

	stakeholder: string;

	allDatasets: ProjectDatasetViewModel[];

	datasetDropdowns: UploadDropdownModel[];

	readonly dataFileForm = new FormGroup({
		datasetControl: new FormControl(),
		descriptionControl: new FormControl("", [Validators.maxLength(255)]),
		refreshDateControl: new FormControl(new Date()),
		newDatasetControl: new FormControl("", [Validators.maxLength(255)]),
	});

	currentLanguageCode: string;

	fileToUpload: File | null = null;

	spinnerMode = "indeterminate";
	fileName = "";
	isUploadFailed = false;
	isUploadStarted = false;
	isNewDataset = false;
	isNewProject = false;
	isUnsupportedFileType = false;
	isUnsupportedFileSize = false;
	
	readonly requiredFileType = '.pdf,.csv,.xlsx,.xls';
	readonly supportedFileTypes = [ 
		"application/vnd.ms-excel", 
		"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", 
		"application/pdf",
		"text/csv",
	];
	readonly maxFileSizeMb = 10;

	selectedDataset: string;
	selectedProjectName: string;
	filesInDataset: IDatasetFile[] = [];

	constructor(
		private translateService: TranslateService,
		private activatedRoute: ActivatedRoute,
		private datasetService: ProjectDatasetService,
		private popupService: ConfirmationPopupService,
		public themeService: ThemeService,
	) { }

	ngOnInit(): void {
		this.currentLanguageCode = this.translateService.currentLang;
		this.stakeholder =  this.activatedRoute.snapshot.params.stakeholder || this.activatedRoute.snapshot.queryParamMap.get('stakeholder');
		
		this.initDatasetsDropdown();
	}

	initDatasetsDropdown(): void {
		this.clearDatasetInfo();

		this.datasetService.getProjectDataSetViewModel(this.stakeholder, null, true).subscribe(datasets => {
			this.allDatasets = datasets;
			this.selectedProjectName = this.allDatasets.find(x => x.projectCode === this._projectCode)?.projectTitle;	

			this.datasetDropdowns = this.getDistinctByKey(this.allDatasets.filter(x => x.projectCode === this._projectCode), "datasetName");
			if (!this.isMetadataUpdate) this.datasetDropdowns.unshift({name: this.translateService.instant("DownloadDataset.AddNewDataset"), key: this.translateService.instant("DownloadDataset.AddNewDataset")});
		});
	}

	clearDatasetInfo(): void {
		this.dataFileForm.reset();
		this.dataFileForm.controls['refreshDateControl'].setValue(new Date());
		this.filesInDataset = [];
	}

	onDatasetChange() {
		this.isNewDataset = false;
		this.selectedDataset = this.dataFileForm.get('datasetControl').value;

		if (this.selectedDataset === this.translateService.instant("DownloadDataset.AddNewDataset")) {
			this.isNewDataset = true;
			this.dataFileForm.controls['descriptionControl'].setValue("");
			this.filesInDataset = [];
		} else {
			const theseDataSets = this.allDatasets.filter(x => x.datasetName === this.selectedDataset).sort((a, b) => new Date(b.lastRefreshedDate).getTime() - new Date(a.lastRefreshedDate).getTime());
			this.dataFileForm.controls['descriptionControl'].setValue(theseDataSets[0].datasetDescription || "");

			this.filesInDataset = theseDataSets.map(x => { return { fileName: x.fileNames, refreshDate: x.lastRefreshedDate }});

			if (this.isMetadataUpdate) {
				this.isNewDataset = true;
				this.dataFileForm.get('newDatasetControl').setValue(theseDataSets[0].datasetName);
			}
		}
	}

	onFileSelected(event: Event): void {
		const input = event.target as HTMLInputElement;
		const file = input.files[0];
		this.fileName = input.files[0].name;

		if (this.isValidFile(file)) {
			this.fileToUpload = file;
		}
	}

	onUploadFile(owerwriteFile: boolean = false) {
		this.isUploadFailed = false;
		this.isUploadStarted = true;

		if (this.fileToUpload) {
			const datasetName = this.isNewDataset ? this.dataFileForm.get('newDatasetControl').value : this.dataFileForm.get('datasetControl').value;
			const refreshDate = this.dataFileForm.get('refreshDateControl').value;
			const description = this.dataFileForm.get('descriptionControl').value;

			const lookupMetadata = new DatasetModel(this.fileToUpload.name, this.fileToUpload.name, new Date(refreshDate), null, this.fileToUpload.name);
			const projectMetadata = new ProjectDatasetModel(this.stakeholder.toUpperCase(), this._projectCode, this.selectedProjectName, this.fileToUpload.name, null, datasetName, description, this.fileToUpload.name);

			this.datasetService.uploadDataset(projectMetadata, lookupMetadata, this.fileToUpload, owerwriteFile).subscribe(
				() => this.onCompleted('DownloadDataset.Uploaded'), 
				error => this.onError(error, 'DownloadDataset.UploadError'));
		}
	}

	onError(error: any, defaultErrorDictionaryKey: string) {
		const errorText = error.error instanceof String ? error.error : error.message
		this.isUploadFailed = true;
		this.isUploadStarted = false;

		if (this.isFileExistsError(errorText)) {
			this.showOverwriteConfirmationDialog();
		} else {
			this.popupService.openConfirmMessage(null, this.translateService.instant(defaultErrorDictionaryKey) + '.\n\n' + errorText);
		}
	}

	resetForm() {
		this.fileName = "";
		this.isNewDataset = false;
		this.isUploadFailed = false;
		this.fileSelector.nativeElement.value = "";
		this.fileToUpload = null;
		this.selectedDataset = "";
		this.isUploadStarted = false;
		this.isUnsupportedFileType = false;
		this.isUnsupportedFileSize = false;
		this.filesInDataset = [];

		this.dataFileForm.reset();
		this.form.resetForm();
		this.dataFileForm.get('refreshDateControl').reset(new Date());
	}

	onCompleted(dictionaryKey: string) {
		this.resetForm();
		this.initDatasetsDropdown();
		this.popupService.openConfirmMessage(dictionaryKey);
	}

	onUpdateMetadata() {
		const oldDatasetName = this.dataFileForm.get('datasetControl').value;
		const datasetName = this.dataFileForm.get('newDatasetControl').value;
		const description = this.dataFileForm.get('descriptionControl').value;
		
		const projectMetadata = new ProjectDatasetModel(this.stakeholder.toUpperCase(), this._projectCode, this.selectedProjectName, null, null, datasetName, description, null);

		this.datasetService.updateMetadata(oldDatasetName, projectMetadata).subscribe(async () => {
			this.onCompleted('DownloadDataset.Updated');
		}, error => this.onError(error, 'DownloadDataset.UpdateError'));
	}

	isUploadDisabled(): boolean {
		const isFormValid = this.dataFileForm.get('descriptionControl').valid && 
		(	this.isNewDataset 
			? this.dataFileForm.get('newDatasetControl').valid
			: this.dataFileForm.get('datasetControl').valid
		);

		return !(this.fileToUpload && this.selectedDataset && isFormValid) 
			|| this.isUploadStarted 
			|| this.isUnsupportedFileType 
			|| this.isUnsupportedFileSize;
	}

	private isValidFile(file: File): boolean {
		this.isUnsupportedFileType = false;
		this.isUnsupportedFileSize = false;

		if(!this.supportedFileTypes.includes(file.type)) {
			this.isUnsupportedFileType = true;
		}
		const fileSizeMb = Math.round(file.size / 1024 / 1024);
		if (fileSizeMb > this.maxFileSizeMb) {
			this.isUnsupportedFileSize = true;
		}

		return !(this.isUnsupportedFileType || this.isUnsupportedFileSize);
	}

	private showOverwriteConfirmationDialog() {
		const existsInDatasets = this.getFileDatasets(this.fileToUpload.name);

		if (existsInDatasets.length) {
			const message = this.translateService.instant('DownloadDataset.UploadedFileExists') + 
			existsInDatasets + 
			this.translateService.instant('DownloadDataset.ConfirmOverwrite');

			const dialogRef = this.popupService.openConfirmDialog(null, message);

			dialogRef.afterClosed().subscribe(dialogResult => {
				if (dialogResult) this.onUploadFile(true); 
			});
		} else {
			this.onUploadFile(true);
		}
	}

	private getFileDatasets(fileName: string): string {
		return this.allDatasets.filter(x => x.datasetFileName.includes(fileName)).map(x => `${x.datasetName}`).join("\n");
	}

	private isFileExistsError(error: string) {
		const regex = /A file with the name [a-zA-Z0-9_\-\/\ \(\)'.]* already exists/g;
		return error.match(regex);
	}

	private getDistinctByKey(datasets: any[], key: string): UploadDropdownModel[] {
		return datasets
			.map(x => x[key])
			.filter(x => x)
			.filter((v, i, a) => a.indexOf(v) === i)
			.map(x => {return { name: x, key: x }})
			.sort((a, b) => a.name.localeCompare(b.name)) as UploadDropdownModel[];
	}
}

interface IDatasetFile {
	fileName: string,
	refreshDate: Date
};