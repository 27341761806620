<div class="body" name="top" [ngClass]="{'app-light-theme': !themeService.isDark, 'app-dark-theme': themeService.isDark}">
    <a (click)="onClick($event)" class="button" aria-hidden="true">
        <mat-icon class="arrow">arrow_upward</mat-icon>
    </a>

    <h1 id="maincontent" tabindex="0">{{'Terms.Header' | translate}}</h1>
    <p tabindex="0" [innerHTML]="'Terms.Message' | translate"></p>

    <div class="terms">
		<h1 tabindex="0">{{'Terms.WhoWeAre.Header' | translate}}</h1>
		<p tabindex="0" [innerHTML]="'Terms.WhoWeAre.Paragraph' | translate"></p>

		<h1 tabindex="0">{{'Terms.AccountAndLogin.Header' | translate}}</h1>
		<p tabindex="0" [innerHTML]="'Terms.AccountAndLogin.Paragraph' | translate"></p>

		<h1 tabindex="0">{{'Terms.AcceptableUse.Header' | translate}}</h1>
		<p tabindex="0" class="no-margin-bottom" [innerHTML]="'Terms.AcceptableUse.Paragraph' | translate"></p>
		<div tabindex="0" class="indent">
			<ul>
				<li [innerHTML]="'Terms.AcceptableUse.Point1' | translate"></li>
				<li [innerHTML]="'Terms.AcceptableUse.Point2' | translate"></li>
				<li [innerHTML]="'Terms.AcceptableUse.Point3' | translate"></li>
				<li [innerHTML]="'Terms.AcceptableUse.Point4' | translate"></li>
				<li [innerHTML]="'Terms.AcceptableUse.Point5' | translate"></li>
				<li [innerHTML]="'Terms.AcceptableUse.Point6' | translate"></li>
				<li [innerHTML]="'Terms.AcceptableUse.Point7' | translate"></li>
				<li [innerHTML]="'Terms.AcceptableUse.Point8' | translate"></li>
				<li [innerHTML]="'Terms.AcceptableUse.Point9' | translate"></li>
			</ul>
		</div>

		<h1 tabindex="0">{{'Terms.OnlineServices.Header' | translate}}</h1>
		<p tabindex="0" [innerHTML]="'Terms.OnlineServices.Paragraph' | translate"></p>

		<h1 tabindex="0">{{'Terms.Copyright.Header' | translate}}</h1>
		<p tabindex="0" [innerHTML]="'Terms.Copyright.Paragraph' | translate"></p>

		<h1 tabindex="0">{{'Terms.Trademark.Header' | translate}}</h1>
		<p tabindex="0" [innerHTML]="'Terms.Trademark.Paragraph' | translate"></p>

		<h1 tabindex="0">{{'Terms.Exemptions.Header' | translate}}</h1>
		<p tabindex="0" class="no-margin-bottom" [innerHTML]="'Terms.Exemptions.Paragraph' | translate"></p>
		<div tabindex="0" class="indent">
			<ul>
				<li [innerHTML]="'Terms.Exemptions.a' | translate"></li>
				<li [innerHTML]="'Terms.Exemptions.b' | translate"></li>
				<li [innerHTML]="'Terms.Exemptions.c' | translate"></li>
				<li [innerHTML]="'Terms.Exemptions.d' | translate"></li>
				<li [innerHTML]="'Terms.Exemptions.e' | translate"></li>
			</ul>
		</div>

		<h1 tabindex="0">{{'Terms.NonEndorsement.Header' | translate}}</h1>
		<p tabindex="0" [innerHTML]="'Terms.NonEndorsement.Paragraph' | translate"></p>

		<h1 tabindex="0">{{'Terms.LinksToOtherWebsites.Header' | translate}}</h1>
		<p tabindex="0" [innerHTML]="'Terms.LinksToOtherWebsites.Paragraph' | translate"></p>

        <h1 tabindex="0">{{'Terms.Licence.Header' | translate}}</h1>
		<h2 tabindex="0">{{'Terms.Licence.Point1.Header' | translate}}</h2>
		<p tabindex="0" [innerHTML]="'Terms.Licence.Point1.Paragraph' | translate"></p>
		<h2 tabindex="0">{{'Terms.Licence.Point2.Header' | translate}}</h2>
    	<p tabindex="0" [innerHTML]="'Terms.Licence.Point2.Paragraph' | translate"></p>		
		<h2 tabindex="0">{{'Terms.Licence.Point3.Header' | translate}}</h2>
		<p tabindex="0" class="no-margin-bottom" [innerHTML]="'Terms.Licence.Point3.Paragraph' | translate"></p>
		<div tabindex="0" class="indent">
			<ul>
				<li [innerHTML]="'Terms.Licence.Point3.a' | translate"></li>
				<li [innerHTML]="'Terms.Licence.Point3.b' | translate"></li>
				<li [innerHTML]="'Terms.Licence.Point3.c' | translate"></li>
				<li [innerHTML]="'Terms.Licence.Point3.d' | translate"></li>
				<li [innerHTML]="'Terms.Licence.Point3.e' | translate"></li>
			</ul>
		</div>
		<p tabindex="0" [innerHTML]="'Terms.Licence.Point3.Conclusion' | translate"></p>

        <h1 tabindex="0">{{'Terms.Ownership.Header' | translate}}</h1>
        <p tabindex="0" [innerHTML]="'Terms.Ownership.Paragraph' | translate"></p>

		<h1 tabindex="0">{{'Terms.NoWarranty.Header' | translate}}</h1>
		<p tabindex="0" [innerHTML]="'Terms.NoWarranty.Paragraph' | translate"></p>

        <h1 tabindex="0">{{'Terms.Liability.Header' | translate}}</h1>
        <p tabindex="0" [innerHTML]="'Terms.Liability.Paragraph' | translate"></p>

        <h1 tabindex="0">{{'Terms.Law.Header' | translate}}</h1>
        <p tabindex="0" [innerHTML]="'Terms.Law.Paragraph' | translate"></p>

        <h1 tabindex="0">{{'Terms.General.Header' | translate}}</h1>
        <p tabindex="0" [innerHTML]="'Terms.General.Paragraph' | translate"></p>

		<p tabindex="0">
			{{'Terms.Dates.Created' | translate}} 02/03/2021<br>
			<!-- {{'Terms.Dates.Updated' | translate}} updated date if any <br> -->
			{{'Terms.Dates.Published' | translate}} 03/03/2021
		</p>
    </div>
</div>