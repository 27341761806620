<div [ngClass]="{'app-light-theme': !themeService.isDark, 'app-dark-theme': themeService.isDark}">
    <div class="title">
        <h1 id="maincontent" tabindex="0">{{'Help.Title' | translate}}{{'Help.TitleExt' | translate}}</h1>
        <p tabindex="0">{{'Help.Message' | translate}}</p>
    </div>
    <div class="content">
        <div class="full-width" fxLayout="column" fxLayout.gt-md="wrap" fxLayoutAlign="center">
            <mat-card>
                <h2><a routerLink="/user-guides"><img src="assets/img/word_document_icon.svg" aria-hidden="true" alt="">{{'UserGuides.Title' | translate}}</a></h2>
                <mat-divider></mat-divider>
                <div class="bottom-card" fxLayout="column" fxLayoutAlign="space-between flex-end" fxLayoutAlign.gt-smd="space-between center">
                    <p>{{'UserGuides.Description' | translate}}</p>
                    <a routerLink="/user-guides"><button class="arrow" mat-stroked-button><mat-icon>arrow_right_alt</mat-icon> {{'UserGuides.VisitLibrary' | translate}}</button></a>
                </div>
            </mat-card>
            <mat-card>
                <h2><a routerLink="/videos"><img src="assets/img/video_icon.svg" aria-hidden="true" alt="">{{'VideoGallery.Title' | translate}}</a></h2>
                <mat-divider></mat-divider>
                <div class="bottom-card" fxLayout="column" fxLayoutAlign="space-between flex-end" fxLayoutAlign.gt-smd="space-between center">
                    <p>{{'Help.Videos.Paragraph' | translate}}</p>
                    <button class="arrow" mat-stroked-button  routerLink="/videos"><mat-icon>arrow_right_alt</mat-icon> {{'Help.Videos.Button' | translate}}</button>
                </div>
            </mat-card>
            <mat-card>
                <h2><a routerLink="/glossary"><img src="assets/img/glossary_icon.svg" aria-hidden="true" alt="">{{'Help.Glossary.Title' | translate}}</a></h2>
                <mat-divider></mat-divider>
                <div class="bottom-card" fxLayout="column" fxLayoutAlign="space-between flex-end" fxLayoutAlign.gt-smd="space-between center">
                    <p>{{'Help.Glossary.Paragraph' | translate}}</p>
                    <button class="arrow" mat-stroked-button routerLink="/glossary"><mat-icon>arrow_right_alt</mat-icon> {{'Help.Glossary.Button' | translate}}</button>
                </div>
            </mat-card>
        </div>
    </div>
</div>